import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_door_log_search = _resolveComponent("door-log-search")
  const _component_call_history_search = _resolveComponent("call-history-search")
  const _component_capture_search = _resolveComponent("capture-search")
  const _component_temperature_search = _resolveComponent("temperature-search")
  const _component_motion_alert_search = _resolveComponent("motion-alert-search")
  const _component_audit_logs_search = _resolveComponent("audit-logs-search")

  return (_openBlock(), _createBlock("div", null, [
    (_ctx.type==='doorLogs')
      ? (_openBlock(), _createBlock(_component_door_log_search, {
          key: 0,
          "search-data": _ctx.searchData,
          onUpdateToList: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emit('updateToList', $event))),
          onShowExportDialog: _cache[2] || (_cache[2] = ($event: any) => (_ctx.emit('showExportDialog')))
        }, null, 8, ["search-data"]))
      : _createCommentVNode("", true),
    (_ctx.type==='callHistory')
      ? (_openBlock(), _createBlock(_component_call_history_search, {
          key: 1,
          "search-data": _ctx.searchData,
          onUpdateToList: _cache[3] || (_cache[3] = ($event: any) => (_ctx.emit('updateToList', $event))),
          onShowExportDialog: _cache[4] || (_cache[4] = ($event: any) => (_ctx.emit('showExportDialog')))
        }, null, 8, ["search-data"]))
      : _createCommentVNode("", true),
    (_ctx.type==='capture')
      ? (_openBlock(), _createBlock(_component_capture_search, {
          key: 2,
          "search-data": _ctx.searchData,
          onUpdateToList: _cache[5] || (_cache[5] = ($event: any) => (_ctx.emit('updateToList', $event))),
          onShowExportDialog: _cache[6] || (_cache[6] = ($event: any) => (_ctx.emit('showExportDialog')))
        }, null, 8, ["search-data"]))
      : _createCommentVNode("", true),
    (_ctx.type==='temperatureLogs')
      ? (_openBlock(), _createBlock(_component_temperature_search, {
          key: 3,
          "search-data": _ctx.searchData,
          onUpdateToList: _cache[7] || (_cache[7] = ($event: any) => (_ctx.emit('updateToList', $event))),
          onShowExportDialog: _cache[8] || (_cache[8] = ($event: any) => (_ctx.emit('showExportDialog'))),
          onUnitChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.emit('unitChange',$event)))
        }, null, 8, ["search-data"]))
      : _createCommentVNode("", true),
    (_ctx.type==='motionAlerts')
      ? (_openBlock(), _createBlock(_component_motion_alert_search, {
          key: 4,
          "search-data": _ctx.searchData,
          onUpdateToList: _cache[10] || (_cache[10] = ($event: any) => (_ctx.emit('updateToList', $event)))
        }, null, 8, ["search-data"]))
      : _createCommentVNode("", true),
    (_ctx.type==='auditLogs')
      ? (_openBlock(), _createBlock(_component_audit_logs_search, {
          key: 5,
          "search-data": _ctx.searchData,
          onUpdateToList: _cache[11] || (_cache[11] = ($event: any) => (_ctx.emit('updateToList', $event)))
        }, null, 8, ["search-data"]))
      : _createCommentVNode("", true)
  ]))
}