
import {
    defineComponent, watch, ref,
    PropType
} from 'vue';
import { format } from '@/methods/date';
import { AuditLogsSearchData } from './all.type';

const typeOptionList = [{
    label: WordList.ProperAllTextAuditCategoryLogin,
    value: 'Login'
}, {
    label: WordList.ProperAllTextAuditCategoryUser,
    value: 'User'
}, {
    label: WordList.ProperAllTextAuditCategoryAccess,
    value: 'Access'
}, {
    label: WordList.ProperAllTextAuditCategoryCommunity,
    value: 'Community'
}, {
    label: WordList.ProperAllTextAuditCategoryDevice,
    value: 'Device'
}];
export default defineComponent({
    props: {
        searchData: {
            type: Object as PropType<AuditLogsSearchData>,
            required: true
        }
    },
    setup(props, { emit }) {
        // 初始化搜索栏数据
        const formData = ref<AuditLogsSearchData>({
            Type: 'all',
            BeginTime: '',
            EndTime: '',
            UserType: 'all'
        });
        watch(() => props.searchData, () => {
            formData.value = { ...props.searchData };
        }, {
            immediate: true
        });

        // 返回搜索条件
        const updateToList = () => {
            const param = { ...formData.value };
            param.BeginTime = format(param.BeginTime, 'YYYY-MM-DD HH:mm:ss');
            param.EndTime = format(param.EndTime, 'YYYY-MM-DD HH:mm:ss');
            emit('updateToList', param);
        };

        return {
            formData,
            updateToList,
            emit,
            typeOptionList
        };
    }
});
