
import { defineComponent, PropType } from 'vue';
import { DoorLogSearchData, AuditLogsSearchData } from '@/views/pm/log/search/all.type';
import { logType } from '@/views/pm/log/all.type';
import { community } from '@/data';
import doorLogSearch from './door-log-search.vue';
import callHistorySearch from './call-history-search.vue';
import captureSearch from './capture-search.vue';
import temperatureSearch from './temperature-log-search.vue';
import motionAlertSearch from './motion-alert-search.vue';
import auditLogsSearch from './audit-log-search.vue';

export default defineComponent({
    components: {
        doorLogSearch,
        callHistorySearch,
        captureSearch,
        temperatureSearch,
        motionAlertSearch,
        auditLogsSearch
    },
    props: {
        type: {
            type: String as PropType<logType>,
            required: true
        },
        searchData: {
            type: Object as PropType<DoorLogSearchData | AuditLogsSearchData>,
            required: true
        }
    },
    setup(props, { emit }) {
        return {
            emit,
            community
        };
    }
});
