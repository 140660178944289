
import {
    defineComponent, watch, ref, Ref,
    computed, PropType
} from 'vue';
import { isCNServer, isRuServer } from '@/util/location';
import { format } from '@/methods/date';
import { TemperatureSearchData } from './all.type';
import './index.less';

export default defineComponent({
    props: {
        searchData: {
            type: Object as PropType<TemperatureSearchData>,
            required: true
        }
    },
    setup(props, { emit }) {
        // 初始化搜索栏数据
        const formData: Ref<TemperatureSearchData> = ref({
            StartTime: '',
            EndTime: '',
            Status: 'all',
            Unit: '0'
        });
        watch(computed(() => props.searchData), () => {
            formData.value = { ...props.searchData };
        }, {
            immediate: true
        });

        watch(computed(() => formData.value.Unit), () => {
            emit('unitChange', formData.value.Unit);
        });

        const StatusOptions = [
            {
                label: WordList.SelectAll,
                value: 'all'
            }, {
                label: WordList.ProperAllTextAbnormal,
                value: '1'
            }, {
                label: WordList.ProperAllTextNormal,
                value: '0'
            }, {
                label: WordList.ProperAllTextLow,
                value: '2'
            }
        ];
        const UnitOptions = [
            {
                label: '℃',
                value: '1'
            }, {
                label: '℉',
                value: '0'
            }
        ];

        // 返回搜索条件
        const updateToList = () => {
            const param = { ...formData.value };
            param.StartTime = format(param.StartTime, 'YYYY-MM-DD HH:mm:ss');
            param.EndTime = format(param.EndTime, 'YYYY-MM-DD HH:mm:ss');
            emit('updateToList', param);
        };

        return {
            isCNServer,
            isRuServer,
            formData,
            updateToList,
            emit,
            StatusOptions,
            UnitOptions
        };
    }
});
